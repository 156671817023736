
import * as R from 'ramda'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import Line from '@/components/Line.vue'
import ColorText from '@/components/ColorText.vue'
import createChart from '@/components/chart/chart'
import { mul } from 'essential/tools/math'
interface ChartData {
  issue: string;
  rateReturn: number;
}
export default defineComponent({
  name: 'ThePerformance',
  components: { Line, ColorText },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const chartRef = ref()
    const model = reactive(props.info)
    model.ratio = R.isEmpty(model.ratio) ? [] : model.ratio
    model.totalRatio = mul(model.totalRatio, 100)
    onMounted(() => {
      const draw = createChart({
        container: chartRef.value,
        color: [50, 107, 254],
      })
      draw.setData(model.ratio.map((item: ChartData) => {
        return {
          c: item.rateReturn,
        }
      }))
    })
    return {
      model,
      chartRef,
    }
  },
})
