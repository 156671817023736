
import { TradeDirection } from '@/types'
import { defineComponent } from 'vue'
import Line from '@/components/Line.vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'ThePositions',
  components: { Line, AsyncNullableList, Time },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const isBuy = (type: number) => type === TradeDirection.BUY

    return {
      isBuy,
    }
  },
})
