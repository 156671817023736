
import { defineComponent } from 'vue'
import Line from '@/components/Line.vue'

export default defineComponent({
  name: 'TheProfile',
  components: { Line },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
})
