
import { defineComponent, ref, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import Line from '@/components/Line.vue'
import Icon from '@/components/Icon.vue'
import TheLabel from '@/modules/copyTrade/components/TheLabel.vue'
import { apiTraderLike } from '@/modules/copyTrade/copyTrade.api'

export default defineComponent({
  name: 'TheDetailHeader',
  components: { Pic, Line, Icon, TheLabel },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const isLike = ref(false)
    const dInfo = shallowRef(props.info)
    isLike.value = Boolean(+props.info.isLike === 1)

    const toggleLike = () => {
      apiTraderLike({ traderId: props.info.traderId, like: Number(!(isLike.value)) }).then(() => {
        if (!(isLike.value)) {
          dInfo.value.likes = dInfo.value.likes * 1 + 1
        } else {
          dInfo.value.likes = dInfo.value.likes * 1 - 1
        }
        isLike.value = !(isLike.value)
      })
    }
    return {
      isLike,
      dInfo,
      toggleLike,
    }
  },
})
