
import { defineComponent, ref, shallowRef } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { apiUpdateRatio } from '@/modules/copyTrade/copyTrade.api'
import Radio from '@/components/Radio.vue'

export default defineComponent({
  name: 'SetMaximumDialog',
  components: { DialogTemplate, Button, Radio },
  props: {
    radioArr: {
      type: Object,
      require: true,
    },
    curruntRadio: {
      type: String,
    },
  },
  setup (props, ctx) {
    const list = shallowRef(props.radioArr)

    const maximumType = ref(0)
    if (props.curruntRadio) {
      maximumType.value = +props.curruntRadio * 10 * 10
    }

    const [commit, progress] = useRequest(apiUpdateRatio)

    const done = (val: number) => {
      ctx.emit('close')
      ctx.emit('confirm', String(val))
    }

    return {
      maximumType,
      list,
      select (value: number) {
        maximumType.value = value
      },
      submit () {
        if (!maximumType.value) {
          return false
        }
        commit({
          positionRatio: String(maximumType.value / 10 / 10),
        }).then(() => done(maximumType.value / 10 / 10))
          .catch()
      },
      progress,
    }
  },
})
