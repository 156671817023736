import requestResult from '@/common/request/request'

export interface ITraderItem {
  copyStatus: number;
  avatar: string;
  copyers: number;
  historyOrderList?: any;
  holdOrderlist?: any;
  labels: string[];
  likes: number;
  nikeName: string;
  profile: string;
  ratio?: number | null;
  strategy: string;
  synopsis: string;
  totalRatio: number;
  traderId: number;
}
export type ILabels = Record<string, string>

export interface ITraderList {
  firstModel: ITraderItem;
  labels: ILabels;
  models: ITraderItem[];
}

export interface RootObject {
  code: string;
  msg: string;
  data: ITraderList;
}

export type TraderListSchema = {

}

export interface IRatioInfo {
  positionRatio: string;
  tbList: string[];
  userId: number;
}

export type LabelSchema = {
  type: number;
  name: string;
}

export type TraderPositionsSchema = {

}

export const getTraderList = requestResult<ITraderList>('/api/trader/list')

export const getCopyerTraderList = requestResult<ITraderList>('/api/trader/copyer/trader/list')

export const readTraderInfo = requestResult<ITraderItem>('/api/trader/info')

export const apiTraderLike = requestResult('/api/trader/like')

export const apiUserRatioInfo = requestResult<IRatioInfo>('/api/trader/copyer/info') // 获取用户跟单占比设置

export const apiCopyAction = requestResult('/api/trader/copy') // 跟单操作

export const apiUpdateRatio = requestResult('/api/trader/copyer/info/update') // 更新占比

export const apiStopCopy = requestResult('/api/trader/stopCopy') // 取消跟单操作
