
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'StopCopyDialog',
  components: { DialogTemplate, Button },
  props: {
    trader: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const done = () => {
      ctx.emit('close')
      ctx.emit('confirm')
    }

    return {
      submit () {
        done()
      },
    }
  },
})
