/**
 * @description chart.ts of tf
 * @author 贝才 beica1@outook.com
 * @date 2020/4/17
 */
import * as R from 'ramda'
import { sub, div } from 'essential/tools/math'
// @todo 解耦工具模块中的数据类型定义
export interface QuoteObject {
    o?: string;
    h?: string;
    l?: string;
    c: string;
  }

export interface ChartOption {
   container: string | Element;
   marginTop?: number;
   marginRight?: number;
   virtualPrecision?: number;
   color: number[];
 }

export interface Point {
   x: number;
   y: number;
 }

export class Chart {
   private readonly container: HTMLElement
   private readonly canvas: HTMLCanvasElement
   private readonly ctx: CanvasRenderingContext2D
   private readonly ratio: number = window.devicePixelRatio || 2
   private readonly color: number[]
   private width = 0
   private height = 0
   private high = 1
   private low = 0
   private fx = 1 // x axis 浮动精度
   private fy = 1 // y axis 浮动精度

   constructor (options: ChartOption) {
     // init
     this.container = Chart.getContainer(options.container)
     this.canvas = Chart.createCanvas()
     this.ctx = this.getContext('2d') as CanvasRenderingContext2D
     this.color = options.color

     this.resize()

     // this.draw()

     this.append()
   }

   private static getContainer = R.ifElse(R.is(String), document.querySelector, R.identity)

   private static createCanvas = () => document.createElement('canvas')

   private getContext (id: string) {
     return this.canvas.getContext(id)
   }

   resize () {
     const { width, height } = this.container.getBoundingClientRect()
     if (width * height === 0) {
       throw new Error('chart container size should not be 0')
     }

     this.height = height
     this.width = width

     const { ratio, canvas, ctx } = this

     canvas.height = height * ratio
     canvas.width = width * ratio
     canvas.style.height = `${height}px`
     canvas.style.width = `${width}px`
     ctx.scale(ratio, ratio)
   }

   colorBg () {
     const lineGrad = this.ctx.createLinearGradient(0, 0, 0, this.height)
     lineGrad.addColorStop(0, `rgba(${this.color.toString()}, 0.3)`)
     lineGrad.addColorStop(0.8, `rgba(${this.color.toString()}, 0)`)

     this.ctx.fillStyle = lineGrad
     this.ctx.fill('nonzero')
   }

   getMeanPoint = R.mergeWith(R.pipe(R.add, R.divide(R.__, 2)))

   curve (control: Point, next: Point) {
     const end = this.getMeanPoint(control, next || control)
     this.ctx.quadraticCurveTo(control.x, control.y, end.x, end.y)
   }

   private draw (points: Point[]) {
     this.ctx.strokeStyle = `rgb(${this.color.toString()})`
     this.ctx.lineWidth = 2
     this.ctx.beginPath()

     const first = R.head(points) as Point
     this.ctx.moveTo(first.x, first.y)
     const data = R.aperture(2, R.tail(points))
     const last = R.last(points) as Point
     data.push([last, last])
     R.map(R.apply(this.curve.bind(this)), data)
     this.ctx.stroke()

     // fill
     this.ctx.strokeStyle = 'transparent'
     this.ctx.lineTo(last.x, this.height)
     this.ctx.lineTo(0, this.height)
     this.ctx.stroke()

     this.ctx.closePath()
     this.colorBg()
   }

   private append () {
     this.container.appendChild(this.canvas)
   }

   private static getFloatRange (start: number, end: number): number {
     return sub(start, end)
   }

   private static getPPI (range: number, size: number) {
     return div(size, range)
   }

   private updateBoundsAndPrecision (high: number, low: number, size: number) {
     this.high = high
     this.low = low
     this.fx = Chart.getPPI(size, this.width)
     const floatRange = Chart.getFloatRange(high, low)
     this.fy = Chart.getPPI(floatRange, this.height - 2)
     // log('floatRange', floatRange, 'high', this.high, 'low', this.low, 'fy', this.fy)
   }

   private getPricePoint (price: number, index: number): Point {
     return {
       x: this.fx * index,
       y: Chart.getFloatRange(this.high, price) * this.fy + 1,
     }
   }

   private transfer (data: QuoteObject[]): Point[] {
     const indexedMap = R.addIndex<QuoteObject, Point>(R.map)
     return indexedMap(R.useWith(this.getPricePoint.bind(this), [R.prop('c'), R.identity]), data)
   }

   setData (data: Array<QuoteObject>) {
     const size = data.length
     if (size) {
       const first = R.head(data) as QuoteObject
       const [high, low] = R.reduce(
         ([max, min], value) => [R.max(max, parseFloat(value.c)), R.min(min, parseFloat(value.c))],
         [parseFloat(first.c), parseFloat(first.c)],
         data,
       )
       this.updateBoundsAndPrecision(high, low, size)
       this.draw(this.transfer(data))
     }
   }

   remove () {
     this.container.removeChild(this.canvas)
   }
}

export default (options: ChartOption) => new Chart(options)
