import { computed } from 'vue'
import state from '@/state'
import { openDialog } from '@/components/popup/popup'
import StopCopyDialog from '@/modules/copyTrade/notification/StopCopyDialog.vue'
import CopyDialog from '@/modules/copyTrade/notification/CopyDialog.vue'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import { apiUserRatioInfo, apiCopyAction, apiStopCopy, ITraderItem } from '@/modules/copyTrade/copyTrade.api'
import SetMaximumDialog from '@/modules/copyTrade/components/SetMaximumDialog.vue'
import { YesOrNo } from '@/types'
import useRequest from '@/hooks/useRequest'

// import useRequest from '@/hooks/useRequest'

const isPrime = computed(() => state.prime.member === YesOrNo.YES)

// 设置占比
export const handleSetRatio = (cb: Fn) => {
  apiUserRatioInfo().then(res => {
    const arr = res.tbList.map(item => {
      return +item * 10 * 10
    })
    if (!res.positionRatio) {
      openDialog(SetMaximumDialog, {
        radioArr: arr,
        curruntRadio: res.positionRatio,
        onConfirm: (val: number) => {
          cb && cb(val)
        },
      })
    } else {
      cb(res.positionRatio)
    }
  })
}

// 处理跟单取消跟单逻辑
export const useCopyTrade = () => {
  const [copy, copying] = useRequest(apiCopyAction)
  const [stopCopy, stoping] = useRequest(apiStopCopy)
  const loading = computed(() => copying.value || stoping.value)

  const toggle = (data: ITraderItem, cb: Fn) => {
    if (!isPrime.value) {
      openDialog(LimitDialogTemplate, {
        i18nPath: 'trader_23',
      })
      return false
    }
    if (data.copyStatus === 0) {
      handleSetRatio((val) => {
        openDialog(CopyDialog, {
          trader: { name: data.nikeName },
          onConfirm: () => {
            copy({ traderId: data.traderId, positionRatio: val }).then(() => {
              cb()
            })
          },
        })
      })
    } else {
      openDialog(StopCopyDialog, {
        trader: { name: data.nikeName },
        onConfirm: () => {
          stopCopy({ traderId: data.traderId }).then(() => {
            cb()
          })
        },
      })
    }
  }
  return {
    loading,
    toggle,
  }
}
