
import { defineComponent } from 'vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import TheHistoryItem from '@/modules/copyTrade/components/TheHistoryItem.vue'

export default defineComponent({
  name: 'TheHistory',
  components: { TheHistoryItem, AsyncNullableList },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup () {
    return {

    }
  },
})
