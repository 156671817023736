
import { TradeDirection } from '@/types'
import { computed, defineComponent, ref, shallowRef } from 'vue'
import Time from '@/components/Time.vue'
import Icon from '@/components/Icon.vue'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'TheHistoryItem',
  components: { Icon, Time },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const model = shallowRef(props.order)
    model.value.profitability = mul(props.order.profitability, 100)

    const isBuy = computed(() => props.order.type === TradeDirection.BUY)

    const show = ref(false)

    return {
      model,
      isBuy,
      show,
      toggleSlide: () => show.value = !(show.value),
    }
  },
})
